<template>
  <UiEmptyState
    :image-src="require('@/assets/img/expired_return_error.svg')"
    :title-text="$t('Oops, the available time to return your order is expired')"
  >
    <template #description>
      <p>{{ $t('Please, read the return policy on our website for more information.') }}</p>
    </template>
    <template
      v-if="allUrls"
      #actions
    >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :href="allUrls"
          :style="brandStyle"
        >{{ $t('Return policy') }}</a>
      </UiButton>
    </template>
  </UiEmptyState>
</template>
<script>
import { brandColourMixin, allUrls } from '@/components/mixins'
import { UiButton, UiEmptyState } from '@sendcloud/ui-library'

export default {
  name: 'ExpiredReturnError',
  components: {
    UiEmptyState,
    UiButton,
  },
  mixins: [brandColourMixin, allUrls],
}
</script>
